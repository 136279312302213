<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                        <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('user-data')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- datumska omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Datumska omejitev (od - do)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Datum od"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Datum do"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- časovna omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Časovna omejitev (od - do)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <v-menu
                                                ref="start_time_menu"
                                                v-model="start_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="start_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="start_time"
                                                    label="Začetni čas"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="start_time_menu"
                                                v-model="start_time"
                                                
                                                @click:minute="$refs.start_time_menu.save(start_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                                <v-menu
                                                ref="end_time_menu"
                                                v-model="end_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="end_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="end_time"
                                                    label="Končni čas"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="end_time_menu"
                                                v-model="end_time"
                                                
                                                @click:minute="$refs.end_time_menu.save(end_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- naprava -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Naprava</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_devices"
                                            :items="devices"
                                            label="Izberi napravo"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <!-- tip dogodka omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip dogodka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_event_types"
                                            :items="event_types"
                                            label="Izberi tip dogodka"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-bookmark-multiple"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"

                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- id kartice -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="card_id"
                                            label="Vpiši ID kartice"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearCardId"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Vstopnica</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="ticket_number"
                                            label="Vpiši številko vstopnice"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearTicketNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style="margin-bottom:15px;"><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov</v-btn>
                                <v-btn  @click="exportData()" color="secondary" dark  block outlined style=""><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov</v-btn>
                                    <v-btn  @click="exportData()" color="secondary" dark text><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                        </v-card>
                </v-flex>
            </v-layout>

            <br>
            <dashboard-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></dashboard-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            dataTableColumnsMenuId="toggle-events-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, isMobileDevice, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
const DashboardDataTable = () => import('@/components/DashboardDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { addExportToQueue } from '@/helpers/services.js'

export default {
    components: {
        DashboardDataTable,
        DataTableToggleColumns
    },


    data: () => ({
        
        card1: true,
        card2: true,

        start_time:null,
        end_time: null,

        selected_devices: [],
        selected_event_types: [],

        restriction_start_date: null,
        restriction_end_date:null,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,

        start_time_menu:false,
        end_time_menu:false,

        event_types: [],
        devices: [],
        user_types: [],
        card_id: null,
        ticket_number: null,

        dataTableSettings: {
            id: 'dtEvents',
            title: 'Seznam dogodkov',
            sortColumn: ['event_timestamp_formatted'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Naziv naprave", value: "device_name", class: "text-xs-left", visibility: true },
                { text: "Vstopnica", value: "ticket_number", class: "text-xs-left", visibility: true },
                { text: "ID kartice", value: "card_id", class: "text-xs-left", visibility: true },
                { text: "Št. kartice", value: "card_number", class: "text-xs-left", visibility: true },
                { text: "Uporabnik", value: "user_name", class: "text-xs-left", visibility: true },
                { text: "Naziv dogodka", value: "event_name", class: "text-xs-left", visibility: true },
                { text: "Čas dogodka", value: "event_timestamp_formatted", class: "text-xs-right", visibility: true },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'dolina-soce/events',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_events',
                totalItems : {
                    event: ''
                },
                search: 'dt-events-search',
                toggleColumnsId: 'toggle-events-list-columns'
            }


        }
    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "events",
                    key: "filter_results_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                startDate: this.restriction_start_date,
                endDate: this.restriction_end_date,
                startTime: this.start_time,
                endTime: this.end_time,
                cardId: this.card_id,
                ticketNumber: this.ticket_number,
                selectedDevices: this.selected_devices.map(item => item.id),
                selectedEventTypes: this.selected_event_types.map(item => item.id),
            }
        }
    },

    methods: {
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        search() {
            EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
        },

        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }
        },

        clearCardId() {
            this.card_id = null
        },

        clearTicketNumber() {
            this.ticket_number = null
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.event_types = response.data.eventTypes
                this.devices = response.data.devices
                this.user_types = response.data.user_types
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        exportData() {
            window.console.log("exporting data ...");
            let parameters = {
                startDate: this.restriction_start_date,
                endDate: this.restriction_end_date,
                startTime: this.start_time,
                endTime: this.end_time,
                cardId: this.card_id,
                ticketNumber: this.ticket_number,
                selectedDevices: this.selected_devices.map(item => item.id),
                selectedEventTypes: this.selected_event_types.map(item => item.id),
            }
            
            addExportToQueue('events', parameters);
        }

        
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        this.card1 = this.$store.getters.appSettings.events.filter_results_open

        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_events",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }

        /*
        if(isMobileDevice(this)) {
            this.card1 = false
        }
        */

        this.setParametersData()
        this.restriction_start_date = getCurrentDate()
        this.restriction_end_date = getCurrentDate()
        this.start_time = "00:01"
        //this.endTime = getCurrentTime()
        this.end_time = "23:59";
    },

    destroyed() {
        EventBus.$off(this.dataTableSettings.id)
        EventBus.$off(this.dataTableSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableSettings.eventBus.search)
        

    }
}

</script>

<style scoped>

</style>